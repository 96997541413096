/** @jsx jsx */
import {jsx} from "@emotion/core";
import {Button, withStyles, Tooltip, TextField, InputAdornment} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import OutsideClickHandler from "react-outside-click-handler";
import Icon, {IconType} from "../../components/ui/icons/Icon";
import RequiresOriginalModal from "./RequiresOriginalModal";
import {TitlePortalContext} from "./TitlePortalContext";
import {
    modalWrapper,
    modalText,
    buttonText,
    dropzoneContent,
    dropzoneStyles,
    dropzoneText,
    uploadingDocText,
    useStyles,
    CustomTooltip,
} from "./uploadDocStyle";
import {submitDocument} from "./TitleCoDocumentUploadQueue";
import LinearProgress from "@material-ui/core/LinearProgress";
import {track} from "autotrack/lib/usage";
import axios from "axios";

export function AddRecordingFees({
                                data,
                            }) {
    const [recordingFee, setRecordingFee] = useState(null);
    const [progress, setProgress] = React.useState(0);
    const [submitStatus, setSubmitStatus] = useState({
        isLoading: false,
        isSuccess: false,
        isError: false,
    });

    const {
        uploadedDocsState,
        uploadModalState,
        uploadInProgressState,
        uploadMissingDocState,
        failedUploadDocsState,
        token,
    } = React.useContext(TitlePortalContext);
    const [uploadedDocs, setUploadedDocs] = uploadedDocsState;
    const [uploadModal, setUploadModal] = uploadModalState;
    const [uploadInProgress, setUploadInProgress] = uploadInProgressState;
    const [uploadMissingDoc, setUploadMissingDoc] = uploadMissingDocState;
    const [failedUploadDocs, setFailedUploadDocs] = failedUploadDocsState;

    const classes = useStyles();
    const isDisabled = !!data.noteTracking;

    const submit = async () => {
        setSubmitStatus({...submitStatus, isLoading: true});
        setUploadInProgress(true);

        try {
            if (recordingFee === '') throw new Error("No Recording Information Entered");

            const url = `${process.env.REACT_APP_API_URL}/api/UploadRecordingFee?token=${token}&feeAmount=${recordingFee}&loanId=${data.loanId}`;
            await axios.post(url);

            setSubmitStatus({
                ...submitStatus,
                isLoading: false,
                isSuccess: true,
            });
            setUploadInProgress(false);
        } catch (error) {
            setSubmitStatus({
                ...submitStatus,
                isLoading: false,
                isError: true,
            });
            setUploadInProgress(false);
        }
    };

    const StyledLinearProgress = withStyles({
        barColorPrimary: {
            backgroundImage:
                "linear-gradient(268deg, #419a8e 98%, #3f8b7d 51%, #256053 3%)",
            width: 140,
            height: 8,
            borderRadius: 4,
        },
    })(LinearProgress);

    const closeModalFunc = () => setUploadModal(false);

    const onSubmitSuccess = () => {
        const newDoc = uploadModal + uploadMissingDoc;

        setUploadedDocs((docs) => docs.add(newDoc));
    };


    useEffect(() => {
        if (submitStatus.isSuccess) {
            setProgress(100);
            onSubmitSuccess();
            closeModalFunc();
        } else if (submitStatus.isError) {
            closeModalFunc();
        } else if (submitStatus.isLoading) {
            const timer = setInterval(() => {
                setProgress((prevProgress) =>
                    prevProgress >= 80 ? 80 : prevProgress + 20
                );
            }, 400);
            return () => {
                clearInterval(timer);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeModalFunc, submitStatus]);

    return uploadInProgress ? (
        <div>
            <span css={uploadingDocText}>Recording Fee Uploading </span>
            <StyledLinearProgress
                className={classes.ProgressBar}
                variant="determinate"
                value={progress}
            />
        </div>
    ) : (
        <OutsideClickHandler onOutsideClick={() => setUploadModal(false)}>
            <div>
                {
                    <>
                        <div style={modalWrapper}>
                            <Tooltip title={isDisabled ? "Recording fee already added" : ""} arrow>
                                <span>
                                    <TextField label={'Enter recording fee'}
                                               color={'secondary'}
                                               disabled={isDisabled}
                                               type="number"
                                               step="0.01"
                                               value={recordingFee}
                                               onChange={(e) => setRecordingFee(e.target.value)}
                                               InputProps={{
                                                   startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                               }}/>
                                    <Button
                                        variant="outlined"
                                        className={classes.UploadButton}
                                        style={{width: '100%'}}
                                        onClick={() => submit()}
                                        disabled={isDisabled}
                                    >
                                        <span css={buttonText}>Submit Recording Fee</span>
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                    </>
                }
            </div>
        </OutsideClickHandler>
    );
}

/** @jsx jsx */
import React, { useState } from "react";
import { jsx } from "@emotion/core";
import {TableCell, makeStyles, Tooltip} from "@material-ui/core";
import MissingDoc  from "./MissingDoc";
import { Button } from "@material-ui/core";
// import Icon, { IconType } from "../../components/ui/icons/Icon";
import { TitlePortalContext } from "./TitlePortalContext";

// #region css
const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    minWidth: 50
  },
}));

const wrapper = {
  display: "grid",
  width: "415px",
  gridTemplateColumns: "3fr 3fr 1fr",
  gap: "16px",
};

const buttonStyles = {
  width: "50px",
  height: "35px",
  borderRadius: "4px",
  border: "solid 0.5px #dcddd9", 
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.09)',
  backgroundColor: '#f5f7f2', 
  fontSize: 12,
  fontWeight: 900,
  color: '#354052',
};

const clickedButtonStyles = {
  backgroundColor: "#354052",
  color: "#fff",
};
// #endregion

const docTypes = {
   mortgage : "Mortgage",
   policy : "Policy",
}

export default function MissingDocs({
  data
}) {
  const classes = useStyles();
  const { uploadedDocsState } = React.useContext(TitlePortalContext);
  const [uploadedDocs, setUploadedDocs] = uploadedDocsState;
  const missingMortgage = data.missingDocs.find((d) => d.docType === docTypes.mortgage);
  const missingPolicy = data.missingDocs.find((d) => d.docType === docTypes.policy);
  const additionalMissingDocs = data.missingDocs.filter(
    (d) => d.docType !== docTypes.mortgage && d.docType !== docTypes.policy
  );
  const missingRecordingFee = data.requiresRecordingFee && 
      data.recordingFee === null &&  
      !uploadedDocs.some((d) => d === data.loanId + 'Recording');
    
  const hasAdditionalMissingDocs = additionalMissingDocs.length !== 0 || missingRecordingFee;
  const additionalCount = additionalMissingDocs.length + (missingRecordingFee ? 1 : 0)

  const { showAllDocsState } = React.useContext(TitlePortalContext);
  const [showAllDocs, setShowAllDocs] = showAllDocsState;

  return (
    <TableCell>
      <div css={wrapper}>
              <div>
                  <MissingDoc
                      key={data.loanId + docTypes.mortgage}
                      data={data}
                      docInfo={{
                          docType: docTypes.mortgage,
                          requiresOriginal: missingMortgage?.requiresOriginal,
                          digitalReceived: missingMortgage?.digitalReceived,
                          requiresFee: missingRecordingFee,
                          disabled: !missingMortgage,
                      }}
                  />
              </div>

        <div>
          <MissingDoc
            key={data.loanId + docTypes.policy}
            data={data}
            docInfo={{
              docType: docTypes.policy,
              requiresOriginal: missingPolicy?.requiresOriginal,
              digitalReceived: missingPolicy?.digitalReceived,
              disabled: !missingPolicy,
            }}
          />
        </div>

        <div >
          {(hasAdditionalMissingDocs) && (
            <Button
              className={classes.buttonRoot}
              style={
                showAllDocs === data.loanId
                  ? { ...buttonStyles, ...clickedButtonStyles }
                  : buttonStyles
              }
              variant="outlined"
              onClick={() => {
                showAllDocs === data.loanId
                  ? setShowAllDocs(0)
                  : setShowAllDocs(data.loanId);
              }}
            >
              +{additionalCount}
            </Button>
          )}
        </div>
      </div>

      {showAllDocs === data.loanId && (
        <div
          css={
            missingMortgage?.requiresOriginal
              ? { ...wrapper, marginTop: 16 }
              : { ...wrapper, marginTop: 8 }
          }
        >
          {additionalMissingDocs
            .sort((a, b) => a.docType.localeCompare(b.docType))
            .map((doc) => (
              <div key={data.loanId + doc.docType}>
                <MissingDoc
                  key={data.loanId + doc.docType}
                  data={data}
                  docInfo={{
                    docType: doc.docType,
                    requiresOriginal: doc.requiresOriginal,
                    digitalReceived: doc.digitalReceived,
                    disabled: false,
                  }}
                />
              </div>
            ))}
            {
                data.requiresRecordingFee &&
                data.recordingFee === null && 
                <div>
                    <MissingDoc
                        key={data.loanId + 'Recording'}
                        data={data}
                        docInfo={{
                            docType: 'Recording',
                            requiresOriginal: false,
                            digitalReceived: false,
                            disabled: false,
                        }}
                    />
                </div>
            }
        </div>
      )}
    </TableCell>
  );
}

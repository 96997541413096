/** @jsx jsx */
import { jsx } from "@emotion/core";
import CheckIcon from "@material-ui/icons/Check";
import React from "react";
import Highlighter from "react-highlight-words";
import { TitlePortalContext } from "./TitlePortalContext";
import {
  uploadedDocText, uploadedDocWrapper
} from "./uploadDocStyle";

export default function UploadedDoc({ docType }) {
  const { searchValueState  } = React.useContext(TitlePortalContext);
  const [searchValue, setSearchValue] = searchValueState;

  function HighlightedWords({ text }) {
    return <Highlighter searchWords={[searchValue]} textToHighlight={text} />;
  }

  return (
    <div css={uploadedDocWrapper}>
      <CheckIcon fontSize="large" css={{ float: "left" }} />
        <span css={uploadedDocText}>
        <HighlightedWords text={docType === 'Municipal Lien Certificate'
            ? 'Tax Cert/Municipal Lien Certificate'
            : docType === 'Note'
                ? 'Tracking'
                : docType}/> Uploaded
      </span>
      </div>
  );
}

/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, {  useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Icon, { IconType } from "../../components/ui/icons/Icon";
import { validPoBox } from "../../Utils";
import { addressBox, modalIcon, modalText, copyBox, copiedText } from "./requiresOriginalStyle";

export default function RequiresOriginalModal({
  data: { clientName, poBox, clientAddress, clientAddressLine2 },
    isNote
}) {
  const [copied, setCopied] = useState(false);

  const fullAddressToCopy = `${clientName} C/O DocProbe ${
    validPoBox(poBox) ? `${clientAddress.includes('1133')?'Mail Code':'PO Box'} ${poBox} ` : ``
  }${clientAddress} ${clientAddressLine2}`;

  const noteAddressToCopy = `${clientName} C/O DocProbe Collateral Dept. 1133 Ocean Avenue Lakewood NJ 08701`;

  return (
    <div css={{marginBottom: 16}}>
      <span css={modalText}>
        Ship <b>{isNote ? 'Note' : 'Original'}</b> to the Following Address:{" "}
      </span>
      <div css={addressBox}>
        <span>
          {clientName}
          {isNote ? (
              <span>
                  <br/>
                  C/O DocProbe Collateral Dept.
                  <br/>
                  1133 Ocean Avenue Lakewood NJ 08701
                  <br/>
              </span>
          ) : (
              <span>
                  <br/>
                  C/O DocProbe
                  <br/>
                  {validPoBox(poBox) && (
                      <span> 
                        {clientAddress.includes('1133') ? 'Mail Code ' : 'PO Box '}{poBox}
                        <br/>
                      </span>
                  )}
                  {clientAddress}
                  <br/>
                  {clientAddressLine2}
              </span>
          )}
          
        </span>
        <CopyToClipboard
          text={isNote ? noteAddressToCopy : fullAddressToCopy}
          onCopy={() => setCopied(true)}
        >
          <div>
            <span
              css={
                !copied
                  ? modalIcon
                  : { ...modalIcon, color: "#3ea848", "&:hover": {} }
              }
            >
              <Icon icon={IconType.ContentCopyIcon} />
            </span>
            {copied && <div css={copiedText}>Copied</div>}
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );
}

/** @jsx jsx */
import {jsx} from "@emotion/core";
import React from "react";
import Highlighter from "react-highlight-words";
import Icon, { IconType } from "../../components/ui/icons/Icon";
import { TitlePortalContext } from "./TitlePortalContext";
import {
  disabledDocTypeButton,
  docTypeButton,
  uploadFileIcon,
} from "./uploadDocStyle";
import { UploadModal } from "./UploadModal";
import UploadedDoc from "./UploadedDoc";
import FailedUploadDoc from "./FailedUploadDoc";
import {AddTracking} from "./AddTracking";
import {AddRecordingFees} from "./AddRecordingFees";

export default function UploadDoc({
  data,
  disabled,
  requiresOriginal,
  digitalReceived,
  requiresFee,
  docType,
}) {
  const {
    uploadModalState,
    uploadInProgressState,
    uploadMissingDocState,
    searchValueState,
  } = React.useContext(TitlePortalContext);
  const [uploadModal, setUploadModal] = uploadModalState;
  const [uploadInProgress, setUploadInProgress] = uploadInProgressState;
  const [uploadMissingDoc, setUploadMissingDoc] = uploadMissingDocState;
  const [searchValue, setSearchValue] = searchValueState;

  const isNote = docType === 'Note';
  const isRecording = docType === 'Recording';
  function HighlightedWords({ text }) {
    return <Highlighter searchWords={[searchValue]} textToHighlight={text} />;
  }

  const renderComponent = () => {
    if (isNote) return  <AddTracking data={data}/>;
    if (isRecording) return <AddRecordingFees data={data}/>;
    return <UploadModal
        data={data}
        requiresOriginal={requiresOriginal}
        digitalReceived={digitalReceived}
        requiresFee={requiresFee}
        missingDoc={uploadMissingDoc}
    />;
  };

  const renderComponentLabel = () => {
    if (isNote) return <span>Add Tracking For Note</span>
    if (isRecording) return <span>Add Recording Fee</span>
    return <>
            <span>
              <HighlightedWords text={docType === 'Municipal Lien Certificate'
                  ? 'Tax Cert/Municipal Lien Certificate'
                  : docType}/>
                      &nbsp;
                      {docType.length > 12 ? "" : "Upload"} &nbsp;
            </span>
            <span css={uploadFileIcon}>
              <Icon
                  icon={
                    disabled
                        ? IconType.DisabledUploadFileIcon
                        : IconType.UploadFileIcon
                  }
              />
            </span>
    </>
  };

  return (
    <>
      {uploadModal === data.loanId && uploadMissingDoc === docType ? (
          <div>
            {renderComponent()}
          </div>
      ) : (
        <button
          style={
            disabled
              ? { ...docTypeButton, ...disabledDocTypeButton }
              : docTypeButton
          }
          onClick={() => {
            setUploadModal(data.loanId);
            setUploadMissingDoc(docType);
          }}
          disabled={disabled || uploadInProgress}
        >
          {renderComponentLabel()}
        </button>
      )}
    </>
  );
}
